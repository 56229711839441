import {SbEvent} from "../../models/SbEvent";
import {Inventory} from "../../models/Inventory";
import React, {ChangeEvent, useEffect, useLayoutEffect, useState} from "react";
import {getInventory} from "../../@api/Inventory";
import {dateToLocaleString} from "../../helpers/DateFormatter";
import "./InventoriesTable.scss"
import TableFilters from "../TableFilters/TableFilters";
import {actionSvg, getColor} from "../../helpers/SvgTheming";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Dropdown} from "react-bootstrap";
import AppModal from "../../ui/AppModal/AppModal";
import HoldForm from "./HoldForm/HoldForm";
import {InventoriesFilterValues} from "../../interfaces/InventoriesFilterValues";
import {QuoteBuilderData} from "../../interfaces/QuoteBuilderData";
import QuoteBuilderForm from "./QuoteBuilderForm/QuoteBuilderForm";
import {Option} from "../../models/Inputs/Option";
import {styled, Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import {getUserDomain} from "../../helpers/GetUserDomain";

const HtmlTooltip = styled(({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>{children}</Tooltip>
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'var(--theme-background)',
        color: 'var(--theme-color)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid var(--theme-subtitle-color)',
    },
}));

const InventoriesTable = () => {
    const filtersInitial = {
        domain: '',
        name: '',
        venue: '',
        category: '',
        from_date: new Date(),
        to_date: '',
        quantity: '',
        section: '',
        public_notes: '',
        from_cost: '',
        to_cost: '',
    };

    const tableHeaders: string[] = [
        "ID",
        "Qty",
        "Section",
        "Row",
        "Price",
        "Ticket Type",
        "Public Notes",
    ];

    const tableHeadersTablet: string[] = [
        "ID",
        "Qty / Price",
        "Section / Row",
        "Ticket Type",
        "Public Notes",
    ];

    const prettyStockTypes = {
        "HARD": "Hard",
        "ELECTRONIC": "E-ticket",
        "FLASH": "Flash",
        "MOBILE_SCREENCAP": "Mobile QR",
        "MOBILE_TRANSFER": "Mobile Transfer",
        "PAPERLESS": "Paperless Walk-In",
        "PAPERLESS_CARD": "Paperless Gift Card",
    };

    const filtersInputsSecond = [
        {type: 'date', name: 'from_date', placeholder: 'Date From'},
        {type: 'date', name: 'to_date', placeholder: 'Date To'},
        {type: 'text', name: 'venue', placeholder: 'Venue'},
        {type: 'number', name: 'quantity', placeholder: 'Quantity'},
    ];

    const filtersInputsThird = [
        {type: 'text', name: 'section', placeholder: 'Section'},
        {type: 'text', name: 'public_notes', placeholder: 'Notes'},
        {type: 'number', name: 'from_cost', placeholder: 'Price From'},
        {type: 'number', name: 'to_cost', placeholder: 'Price To'},
    ];

    const selectedEvents: number[] = [];
    const selectedInventories: number[] = [];
    const user = useSelector((state: RootState) => state.userSlice.user);
    const [filterValues, setFilterValues] = useState<InventoriesFilterValues>(filtersInitial);
    const [categories, setCategories] = useState<Option[]>([]);
    const [data, setData] = useState<SbEvent[]>([]);
    const [showQuoteBuilder, setShowQuoteBuilder] = useState<boolean>(false);
    const [showHold, setShowHold] = useState<number | null>(null);
    const [inventoriesToQuote, setInventoriesToQuote] = useState<QuoteBuilderData[]>([]);
    const [selectedQuoteInventory, setSelectedQuoteInventory] = useState<number>(0);
    const stockTypesMap = new Map<string, string>(Object.entries(prettyStockTypes));

    const filtersInputsFirst = [
        {type: 'text', name: 'name', placeholder: 'Name', noLabel: true},
        {
            type: 'select', name: 'category', placeholder: 'Category', noLabel: true,
            options: [
                {name: 'All Categories', value: ''},
                ...categories
            ],
        },
    ];

    useEffect(() => {
        setInventories(filterValues, true);
    }, [])

    useLayoutEffect(() => {
        // Function to adjust heights
        const adjustHeights = () => {
            // Get all elements with the selector
            const elements = document.querySelectorAll<HTMLTableRowElement>('.inventories__tr-event');

            if (elements.length > 0) {
                // Reset all heights to 'auto' to calculate natural heights
                elements.forEach(el => {
                    el.style.height = '';
                });

                // Find the maximum height
                const maxHeight = Math.max(...Array.from(elements).map(el => el.offsetHeight));

                // Set each element's height to the maximum height
                elements.forEach(el => {
                    el.style.height = `${maxHeight}px`;
                });
            }
        };

        // Call the function initially
        adjustHeights();

        // Optional: Re-adjust heights on window resize
        window.addEventListener('resize', adjustHeights);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', adjustHeights);
        };
    }, []);

    const setInventories = (values: InventoriesFilterValues, isInitial = false) => {
        filterValues.domain = getUserDomain();
        setFilterValues(values);

        getInventory(values)
            .then((response) => {
                setData(response.data.data);

                if (isInitial) {
                    const usedCategories: Option[] = [];
                    const categories = new Map(response.data.data.map((item) => [
                        item.sb_performer.category_name, item.sb_performer.category_name
                    ]));

                    categories.forEach(value => {
                        usedCategories.push({name: value, value: value});
                    });

                    setCategories(usedCategories);
                }
            });
    };

    const handleCheckboxClick = (event: ChangeEvent<HTMLInputElement>) => {
        const checkbox = event.target;
        const isChecked = checkbox.checked;
        const parent = checkbox.parentElement?.parentElement as HTMLElement;

        switch (parent?.className) {
            case 'inventories__tr-head':
                const events = document.getElementsByClassName('inventories__tr-event');

                for (let i = 0; i < events.length; i++) {
                    checkEventInventories(events[i] as HTMLElement, isChecked);
                }

                break;
            case 'inventories__tr-event':
                checkEventInventories(parent, isChecked);
                break;
            case 'inventories__tr-inventory':
                const inventoryId = Number(parent.dataset.inventoryId);

                if (inventoryId) {
                    changeSelectedArray(isChecked, selectedInventories, inventoryId);
                }
        }
    };

    const checkEventInventories = (parent: HTMLElement, isChecked: boolean) => {
        const eventId = Number(parent.dataset.eventId);
        const eventIndex = Number(parent.dataset.eventIndex);
        const eventCheckbox = parent.getElementsByClassName('inventories__checkbox')[0] as HTMLInputElement;

        eventCheckbox.checked = isChecked;

        if (!isNaN(eventId) && !isNaN(eventIndex)) {
            changeSelectedArray(isChecked, selectedEvents, eventId);

            data[eventIndex].inventories.forEach((inventory) => {
                const inventoryTr = document.querySelector(`[data-inventory-id='${inventory.id}']`);

                if (inventoryTr) {
                    const inventoryCheckbox = inventoryTr.getElementsByClassName('inventories__checkbox')[0] as HTMLInputElement;

                    inventoryCheckbox.checked = isChecked;
                    changeSelectedArray(isChecked, selectedInventories, inventory.id);
                }
            });
        }
    };

    const changeSelectedArray = (isChecked: boolean, selected: number[], value: number) => {
        if (isChecked && selected.indexOf(value) === -1) {
            selected.push(value);
        } else if (!isChecked && selected.indexOf(value) !== -1) {
            selected.splice(selected.indexOf(value), 1);
        }
    };

    const handleHoldClose = () => {
        setShowHold(null);
        setInventories(filterValues);
    };

    const requestHold = (id: number) => {
        setShowHold(id);
    };

    const handleQuoteBuilderOpen = () => {
        const tempQuoteInventories: QuoteBuilderData[] = [];

        setSelectedQuoteInventory(selectedInventories[0]);

        data.forEach((event) => {
            const tempEvent: QuoteBuilderData = {
                event_id: event.event_id,
                name: event.name,
                venue: event.venue,
                city: event.city,
                occurs_at: dateToLocaleString(event.occurs_at),
                inventories: []
            };

            event.inventories.forEach((inventory) => {
                if (selectedInventories.includes(inventory.id)) {
                    tempEvent.inventories.push({
                        id: inventory.id,
                        inventory_id: inventory.inventory_id,
                        quantity: (inventory.shown_quantity < inventory.quantity && inventory.shown_quantity)
                            ? inventory.shown_quantity
                            : inventory.quantity,
                        section: inventory.section,
                        row: inventory.row,
                        stock_type: stockTypesMap.get(inventory.stock_type),
                        split_type: inventory.split_type,
                        custom_split: inventory.custom_split,
                        public_notes: inventory.public_notes,
                        old_price: Number(inventory.unit_amount),
                        new_price: Number(inventory.unit_amount),
                        old_currency: inventory.currency_symbol,
                        new_currency: inventory.currency_symbol,
                        commission: 0,
                    });
                }
            });

            if (tempEvent.inventories.length) {
                tempQuoteInventories.push(tempEvent);
            }
        });

        setInventoriesToQuote(tempQuoteInventories);
        setShowQuoteBuilder(true);
    };

    const handleQuoteBuilderClose = () => {
        setShowQuoteBuilder(false);

        const checkboxes = document.getElementsByClassName('inventories__checkbox');

        for (let i = 0; i < checkboxes.length; i++) {
            const checkbox = checkboxes[i] as HTMLInputElement;

            checkbox.checked = false;
        }
    };

    return (
        <>
            <div className="inventories__wrapper">
                <TableFilters
                    filterInitial={filtersInitial}
                    setDataFunction={setInventories}
                    inputsFirst={filtersInputsFirst}
                    inputsSecond={filtersInputsSecond}
                    inputsThird={filtersInputsThird}
                />
                <div className="inventories__wrapper-table">
                    <table className="inventories__table">
                        <thead className="inventories__thead">
                        <tr className="inventories__tr-head">
                            <th className={'cell-check'}>
                                <input className="inventories__checkbox" type="checkbox"
                                       onChange={handleCheckboxClick}/>
                            </th>
                            <th className={'w-100'} colSpan={tableHeaders.length}>
                                <span className={'d-md-none'}>Inventory</span>
                                <table className={'inventories__table tablet inner d-none d-md-table d-lg-none'}>
                                    <thead className="inventories__tbody">
                                    <tr className={'inventories__tr-head'}>
                                        {tableHeadersTablet.map((header) => (
                                            <th key={'th' + header}
                                                className={'cell-' + header.replace(/\s/g, '-').toLowerCase()}>{header}</th>
                                        ))}
                                    </tr>
                                    </thead>
                                </table>
                                <table className={'inventories__table inner d-none d-lg-table'}>
                                    <thead className="inventories__tbody">
                                    <tr className={'inventories__tr-head'}>
                                        {tableHeaders.map((header) => (
                                            <th key={'th' + header}
                                                className={'cell-' + header.replace(/\s/g, '-').toLowerCase()}>{header}</th>
                                        ))}
                                    </tr>
                                    </thead>
                                </table>
                            </th>
                            <th className={'cell-actions'}>
                                <Dropdown>
                                    <Dropdown.Toggle className="dropdown-toogle dropdown-toogle_main">
                                        <img src={actionSvg(user?.color)}
                                             alt="action"
                                             className=""
                                             draggable="false"
                                        />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu_main">
                                    <Dropdown.Item
                                            onClick={() => handleQuoteBuilderOpen()}>
                                            Quote Generator
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </th>
                        </tr>
                        </thead>
                        <tbody className="inventories__tbody">
                        {
                            data.length
                                ? data.map((event: SbEvent, key: number) =>
                                    <React.Fragment key={'event_tr-' + event.id + '-' + key}>
                                        <tr className="inventories__tr-event" data-event-index={key}
                                            data-event-id={event.id}>
                                            <td className={'cell-check'}>
                                                <input className="inventories__checkbox" type="checkbox"
                                                       onChange={handleCheckboxClick}/>
                                            </td>
                                            <td colSpan={8}>
                                                <span>{event.name} | {event.venue} | {event.city} </span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6"
                                                     viewBox="0 0 6 6"
                                                     fill="none">
                                                    <circle cx="3" cy="3" r="3"
                                                            fill={"#" + getColor(user?.color ?? 'white')}/>
                                                </svg>
                                                <span> {dateToLocaleString(event.occurs_at)}</span>
                                            </td>
                                        </tr>
                                        {
                                            event.inventories.map((inventory: Inventory) => {
                                                const inventoryNotes = (
                                                    <React.Fragment>
                                                        {inventory.public_notes?.length > 20 ? (
                                                            <HtmlTooltip title={
                                                                <React.Fragment>
                                                                    {inventory.public_notes?.split('. ').map((line, noteIndex) => (
                                                                        <p style={{marginTop: !noteIndex ? 0 : '0.5rem'}}
                                                                           key={'public-notes-' + inventory.id + '-' + noteIndex}>{`${line}.`.replace('..', '.')}</p>
                                                                    ))}
                                                                </React.Fragment>
                                                            }>
                                                                <div className="text-container">{inventory.public_notes}</div>
                                                            </HtmlTooltip>
                                                        ) : inventory.public_notes}
                                                    </React.Fragment>
                                                );

                                                const handleMobileNotesClick = (event: any) => {
                                                    (event.target as HTMLSpanElement).closest('.cell-public-notes-mobile')?.classList.toggle('expanded');
                                                }

                                                const mobileView = (
                                                    <div className={'mobile-view overflow-hidden d-md-none'}>
                                                        <div>
                                                            <label>Id:</label>
                                                            <span>{inventory.inventory_id}</span>
                                                        </div>
                                                        <div className={'cell-qty'}>
                                                            <label>Qty:</label>
                                                            <span>{(inventory.shown_quantity < inventory.quantity && inventory.shown_quantity)
                                                                        ? inventory.shown_quantity
                                                                        : inventory.quantity}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <label>Section:</label>
                                                            <span>{inventory.section}</span>
                                                        </div>
                                                        <div>
                                                            <label>Row:</label>
                                                            <span>
                                                                {inventory.row}
                                                            </span>
                                                        </div>
                                                        <div className={'cell-price'}>
                                                            <label>Price:</label>
                                                            <span>
                                                                <span>{inventory.currency_icon}</span>
                                                                {inventory.unit_amount}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <label>Ticket Type:</label>
                                                            <span>{stockTypesMap.get(inventory.stock_type)}</span>
                                                        </div>
                                                        {inventory.public_notes?.length > 30 ? (
                                                            <div className={'cell-public-notes-mobile'}>
                                                                <div className={'cell-public-notes-mobile-short'}>
                                                                    <div>{inventory.public_notes.substring(0, 30)}...</div>
                                                                    <div className={'toggle'}
                                                                          onClick={handleMobileNotesClick}>
                                                                        Expand
                                                                    </div>
                                                                </div>
                                                                <div className={'cell-public-notes-mobile-full'}>
                                                                    <div>{inventory.public_notes}</div>
                                                                    <div className={'toggle'}
                                                                          onClick={handleMobileNotesClick}>
                                                                        Collapse
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className={'cell-public-notes'}>
                                                                {inventory.public_notes}
                                                            </div>
                                                        )}
                                                        {/*{inventory.public_notes?.length > 0 && (
                                                            <div className={'notes-wrapper d-flex w-100 overflow-hidden'}>
                                                                <label className={'flex-shrink-0'}>Public Notes:</label>
                                                                <div
                                                                    className={'cell-public-notes' + (inventory.public_notes?.length > 30 ? ' hover-text' : '')}>
                                                                    {inventoryNotes}
                                                                </div>
                                                            </div>
                                                        )}*/}
                                                    </div>
                                                )

                                                const tabletView = (
                                                    <table
                                                        className={'inventories__table inner tablet d-none d-md-table d-lg-none'}>
                                                        <tbody className="inventories__tbody">
                                                        <tr className={'inventories__tr-inventory inverted'}>
                                                            <td className={'cell-id'}>{inventory.inventory_id}</td>
                                                            <td className={'cell-qty-price'}>
                                                                <table className={'tablet-cell'}>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td><label>Qty:</label></td>
                                                                        <td>{
                                                                            (inventory.shown_quantity < inventory.quantity && inventory.shown_quantity)
                                                                                ? inventory.shown_quantity
                                                                                : inventory.quantity
                                                                        }</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><label>Price:</label></td>
                                                                        <td><span>{inventory.currency_icon}</span>
                                                                            {inventory.unit_amount}</td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className={'cell-section-row'}>
                                                                <table className={'tablet-cell'}>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td><label>Sec:</label></td>
                                                                        <td>{inventory.section}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><label>Row:</label></td>
                                                                        <td>{inventory.row}</td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className={'cell-ticket-type'}>{stockTypesMap.get(inventory.stock_type)}</td>
                                                            <td className={'cell-public-notes tablet' + (inventory.public_notes?.length > 30 ? ' hover-text' : '')}>
                                                                {inventoryNotes}
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                )

                                                const desktopView = (
                                                    <table
                                                        className={'inventories__table inner d-none d-lg-table'}>
                                                        <tbody className="inventories__tbody">
                                                        <tr className={'inventories__tr-inventory inverted'}>
                                                            <td className={'cell-id'}>{inventory.inventory_id}</td>
                                                            <td className={'cell-qty'}>{
                                                                (inventory.shown_quantity < inventory.quantity && inventory.shown_quantity)
                                                                    ? inventory.shown_quantity
                                                                    : inventory.quantity
                                                            }</td>
                                                            <td>{inventory.section}</td>
                                                            <td>{inventory.row}</td>
                                                            <td className={'cell-price'}>
                                                                <span>{inventory.currency_icon}</span>
                                                                {inventory.unit_amount}
                                                            </td>
                                                            <td>{stockTypesMap.get(inventory.stock_type)}</td>
                                                            <td className={'cell-public-notes' + (inventory.public_notes?.length > 30 ? ' hover-text' : '')}>
                                                                {inventoryNotes}
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                )

                                                return (
                                                    <React.Fragment key={'inventory_tr' + inventory.id}>
                                                        <tr className="inventories__tr-inventory"
                                                            data-inventory-id={inventory.id}>
                                                            <td className={'cell-check'}>
                                                                <input className="inventories__checkbox" type="checkbox"
                                                                       onChange={handleCheckboxClick}/>
                                                            </td>
                                                            <td className={'w-100'} colSpan={tableHeaders.length}>
                                                                {mobileView}
                                                                {tabletView}
                                                                {desktopView}
                                                            </td>
                                                            <td className={'cell-actions'}>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle className="dropdown-toogle">
                                                                        <img src={actionSvg(user?.color)}
                                                                             alt="action"
                                                                             className=""
                                                                             draggable="false"
                                                                        />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu">
                                                                        <Dropdown.Item
                                                                            onClick={() => requestHold(inventory.id)}>
                                                                        Request Hold
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                        <AppModal
                                                            size={'lg'}
                                                            title="Request Hold"
                                                            component={<HoldForm event={event} inventory={inventory}
                                                                                 showHandle={handleHoldClose}/>}
                                                            show={showHold === inventory.id}
                                                            showHandle={handleHoldClose}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                                )
                                : <tr className="inventories__tr-inventory-empty">
                                    <td colSpan={9}>No Tickets</td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>
                <AppModal title="Quote Builder"
                          size={'lg'}
                          component={
                              <QuoteBuilderForm
                                  data={inventoriesToQuote}
                                  setData={setInventoriesToQuote}
                                  selectedInventory={selectedQuoteInventory}
                                  setSelectedInventory={setSelectedQuoteInventory}/>
                          }
                          show={showQuoteBuilder}
                          showHandle={handleQuoteBuilderClose}/>
            </div>
        </>
    )
}

export default InventoriesTable
