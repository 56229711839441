import React, {FC} from "react";
import './SuccessResponseModal.scss';
import {SbEvent} from "../../../../models/SbEvent";

interface Props {
    quantity?: number,
    event?: SbEvent,
    onClose?: () => void;
    expiryDate?: string | Date
}

const SuccessResponseModal: FC<Props> = ({quantity, event, onClose, expiryDate}) => {
    return (
        <>
            <div className={'success-response'}>
                <div className={'success-response__event-container'}>
                    <p>{quantity} TICKET(S) FOR</p>
                    <div>
                        <h6>{event!.name}</h6>
                        <h6>{event!.venue}, {event!.city}</h6>
                    </div>
                </div>
                <div className={'success-response__event-container'}>
                    <p>ON HOLD UNTIL</p>
                    <h6>{expiryDate?.toLocaleString()}</h6>
                </div>
            </div>
            <div className={'p-2'}>
                <button onClick={onClose} className="button button_submit w-100">
                    DISMISS
                </button>
            </div>
        </>
    )
}

export default SuccessResponseModal;
