import http from "./Instance";
import {SbEvent} from "../models/SbEvent";
import {InventoriesFilterValues} from "../interfaces/InventoriesFilterValues";
import {InitialValuesTypes} from "../interfaces/InitialValuesTypes";
import {QuoteBuilderData} from "../interfaces/QuoteBuilderData";

const urlPrefix = 'inventories/feed';

export const getInventory = (params: InventoriesFilterValues) => {
    return http.get<{ data: SbEvent[] }>(`${urlPrefix}`, {params: params});
}

export const postInventoriesDownload = (userId: number, data: QuoteBuilderData[] | undefined, notes: string) => {
    return http.post<Blob | MediaSource>(`${urlPrefix}/download`, {userId, data, notes}, {responseType: 'blob'});
}

export const postInventoriesHold = (userId: number, inventoryId: number, params: InitialValuesTypes) => {
    return http.post<string>(`${urlPrefix}/${inventoryId}/hold`, {userId, ...params});
}
